import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const PomnikNiepodleglosciCeglowPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Pomnik Niepodległości w gminie Cegłów" />
      <ReturnToPrevPage to={'/gminy/ceglow'}>
        Materiały dotyczące gminy Cegłów
      </ReturnToPrevPage>
      <Header1>Pomnik Niepodległości</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Na historycznym Rynku, obecnym placu Anny Jagiellonki, usytuowano
          Pomnik upamiętniający dziesiątą rocznicę odzyskania Niepodległości.
          Jego uroczyste odsłonięcie nastąpiło jednak dopiero 10 maja 1934 roku.
          Inicjatorami budowy byli wójt Kieliszczyk, sekretarz gminy
          Chodorowski, ksiądz proboszcz Fijałkowski oraz dyrektor szkoły
          Nielepiec. Pomnik powstał dzięki zbiórkom wśród lokalnej społeczności
          oraz wsparciu urzędu gminy, zaś wieńczący go orzeł z brązu został
          ufundowany przez rodzinę Zawadzkich ze Skwarnego, aktywnie działającą
          wówczas w Akcji Katolickiej. Dziś cegłowski Pomnik Niepodległości
          stanowi główne miejsce gminnych obchodów świąt państwowych.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/okladka/" }
      relativePath: { regex: "/ceglow/pomnik-niepodleglosci-ceglow/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: {
        relativePath: { regex: "/ceglow/pomnik-niepodleglosci-ceglow/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default PomnikNiepodleglosciCeglowPage
